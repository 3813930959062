var app;
(function (app) {
    var home;
    (function (home) {
        'use strict';
        var HomeController = /** @class */ (function () {
            /* @ngInject */
            HomeController.$inject = ["$scope", "$element", "$document", "$timeout", "$q", "dataservice", "$state", "$location", "rxData", "$rootScope"];
            function HomeController($scope, $element, $document, $timeout, $q, dataservice, $state, $location, rxData, $rootScope) {
                var _this = this;
                this.$scope = $scope;
                this.$element = $element;
                this.$document = $document;
                this.$timeout = $timeout;
                this.$q = $q;
                this.dataservice = dataservice;
                this.$state = $state;
                this.$location = $location;
                this.rxData = rxData;
                this.$rootScope = $rootScope;
                this.$onInit = function () {
                    rxData.rootScopeOnready().then(function (data) {
                        _this.pageOBJ = data;
                        console.log('what is the page data??', data);
                        $timeout(function () {
                            $scope.$apply();
                        });
                    }, function (err) {
                        _this.pageOBJ = false;
                        console.log('error on home', err);
                    });
                }; // $onInit
            }
            ;
            return HomeController;
        }());
        home.HomeController = HomeController;
        var HomeComponent = /** @class */ (function () {
            function HomeComponent() {
                this.restrict = 'E';
                this.controllerAs = 'vm';
                this.templateUrl = 'dist/js/app.home.html';
                this.controller = HomeController;
            }
            return HomeComponent;
        }());
        angular
            .module('app.home', []);
        angular
            .module('app.home').component('home', new HomeComponent());
    })(home = app.home || (app.home = {}));
})(app || (app = {}));
